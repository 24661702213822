import React, { useState, useEffect } from "react";
import bgVideo from "../assets/media/bgVideo.mp4";
import wiomLogo from "../assets/svgs/wiom_logo_new.svg";
import { Link } from "react-router-dom";
import partnerPlus from "../assets/svgs/partnerPlus.svg";
import facebookIcon from "../assets/svgs/Facebook.svg";
import twitterIcon from "../assets/svgs/Twitter.svg";
import instagramIcon from "../assets/svgs/Instagram.svg";
import linkedinIcon from "../assets/svgs/LinkedIn.svg";
import youtubeIcon from "../assets/svgs/Vector.svg";
import MainContent from "./MainContent";
import WiomLogoBottom from "../assets/svgs/wiomLogoBottom.svg";

const Navbar = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth <= 1030 || false
  );
  // Update state on window resize
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 1030);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <nav
      style={{
        ...styles.navbar,
        padding: isSmallScreen ? "8px 16px" : "10px 30px",
      }}
    >
      <Link to="/newHomepage" style={styles.navbarLogo}>
        <img src={wiomLogo} alt="Wiom Logo" style={styles.logo} />
      </Link>
      <div style={styles.navbarLinks}>
        <a href="tribe.html" style={styles.meetTheTribe}>
          Meet the Tribe
        </a>
      </div>
    </nav>
  );
};

// Bottom Bar Component
const BottomBar = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredContact, setIsHoveredContact] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth <= 1030 || false
  );

  const [isHoveredGridHome, setIsHoverGridHome] = useState(false);
  const [isHoveredEsg, setIsHoverEsg] = useState(false);

  const [isHoveredGridMeet, setIsHoverGridMeet] = useState(false);

  const [isHoveredGridTnc, setIsHoverGridTnc] = useState(false);

  const [isHoveredGridCareer, setIsHoverGridCareer] = useState(false);
  const [isMiddleScreen, setIsMiddleScreen] = useState(false);

  // const [isHoveredGrid, setIsHoverGrid] = useState(false);

  // Update state on window resize
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
  
      // Below or equal to 1030 => small screen
      if (width <= 1030) {
        setIsSmallScreen(true);
        setIsMiddleScreen(false);
      }
      // Between 931 and 1540 => middle screen
      else if (width <= 1540) {
        setIsSmallScreen(false);
        setIsMiddleScreen(true);
      }
      // Above 1540
      else {
        setIsSmallScreen(false);
        setIsMiddleScreen(false);
      }
    };
  
    // Call once to set initial state
    handleResize();
  
    // Listen to window resize
    window.addEventListener("resize", handleResize);
  
    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <footer
    style={{
      ...styles.bottomBar,
      padding: isSmallScreen
        ? "0px 16px"  // If small screen
        : isMiddleScreen
          ? "0px 40px" // If middle screen
          : "0px 240px", // Else (large screen)
    }}
    >
      <div style={styles.sectionContainer}>
        <div
          style={{
            ...styles.sectionOne,
            alignItems: isSmallScreen ? "flex-start" : "flex-end",
            textAlign: isSmallScreen ? "left" : "right",
            gap: isSmallScreen ? "48px" : "10px",
            flexDirection: isSmallScreen ? "column" : "row",
            // gap: isSmallScreen ? "48px" : "10px",
          }}
        >
          <div style={styles.rowItem}>
            <Link to="/newHomepage" style={styles.navbarLogoBottom}>
              <img
                src={WiomLogoBottom}
                alt="Wiom Logo"
                style={styles.logoBottom}
              />
            </Link>
            <button
              style={{
                ...styles.contactButton,
                backgroundColor: isHoveredContact ? "#D9008D" : "#161021",
                width: isSmallScreen ? "90vw" : "",
              }}
              onMouseEnter={() => setIsHoveredContact(true)}
              onMouseLeave={() => setIsHoveredContact(false)}
              onClick={() => {
                window.open("tel:8880322222");
              }}
            >
              Contact us: <span style={styles.boldText}>88803 22222</span>
            </button>
          </div>
          <div
            className="frame"
            style={{
              ...styles.gridContainer,
              gap: isSmallScreen ? "20px" : "20px 120px", // Dynamic gap based on screen size
            }}
          >
            <div
              className="gridItem"
              onMouseEnter={() => setIsHoverGridHome(true)}
              onMouseLeave={() => setIsHoverGridHome(false)}
            >
              <Link
                to="/"
                style={{
                  ...styles.textWrapper,
                  color: isHoveredGridHome ? "#D9008D" : "#faf9fc",
                }}
              >
                Home
              </Link>
            </div>
            <div
              className="gridItem"
              onMouseEnter={() => setIsHoverEsg(true)}
              onMouseLeave={() => setIsHoverEsg(false)}
            >
              <a
                href="/esg.pdf"
                style={{
                  ...styles.textWrapper3,
                  color: isHoveredEsg ? "#D9008D" : "#faf9fc",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                ESG Policy
              </a>
            </div>
            <div
              className="gridItem"
              onMouseEnter={() => setIsHoverGridMeet(true)}
              onMouseLeave={() => setIsHoverGridMeet(false)}
            >
              <a
                href="tribe.html"
                style={{
                  ...styles.textWrapper2,
                  color: isHoveredGridMeet ? "#D9008D" : "#faf9fc",
                }}
              >
                Meet the Tribe
              </a>
            </div>
            <div
              className="gridItem"
              onMouseEnter={() => setIsHoverGridTnc(true)}
              onMouseLeave={() => setIsHoverGridTnc(false)}
            >
              <a
                href="https://config.wiom.in/customer/tnc_Customer.pdf"
                style={{
                  ...styles.textWrapper2,
                  color: isHoveredGridTnc ? "#D9008D" : "#faf9fc",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms &amp; Conditions
              </a>
            </div>
            <div
              className="gridItem"
              onMouseEnter={() => setIsHoverGridCareer(true)}
              onMouseLeave={() => setIsHoverGridCareer(false)}
            >
              <a
                href="mailto:hr@wiom.in"
                style={{
                  ...styles.textWrapper3,
                  color: isHoveredGridCareer ? "#D9008D" : "#faf9fc",
                }}
              >
                Careers
              </a>
            </div>
            {/* <div className="gridItem">
              <a
                href="/esg.pdf"
                style={styles.textWrapper3}
                target="_blank"
                rel="noopener noreferrer"
              >
                ESG Policy
              </a>
            </div> */}

            {/* <div className="gridItem">
              <Link to="/faqs" style={styles.textWrapper3}>
                FAQs
              </Link>
            </div> */}
          </div>
          <Link to="/partnerPlus" style={{ textDecoration: "none" }}>
            <div
              className="frame"
              style={{
                ...styles.partnerPlusFrame,
                backgroundColor: isHovered ? "#D9008D" : "#161021",
                width: isSmallScreen ? "80vw" : "",
                justifyContent: isSmallScreen ? "center" : "",
                marginBottom: isSmallScreen ? "" : "50px",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img
                className="img"
                alt="Partner Plus Logo"
                src={partnerPlus}
                style={styles.partnerPlusLogo}
              />
              <div className="content" style={styles.partnerPlusContent}>
                <div className="header" style={styles.partnerPlusHeader}>
                  Grow business with Wiom
                </div>
                <p className="description" style={styles.partnerPlusText}>
                  <span style={styles.partnerPlusTextWrapper}>Get</span>
                  <span style={styles.partnerPlusTextSpan}> Partner+</span>
                  <span style={styles.partnerPlusTextWrapper}> App</span>
                </p>
              </div>
            </div>
          </Link>
        </div>
        <hr style={styles.divider} />
        <div
          style={{
            ...styles.sectionTwo,
            padding: isSmallScreen ? "16px 0px" : "12px 0px",
            gap: isSmallScreen ? "32px" : "auto",
          }}
        >
          <div style={styles.leftColumn}>
            <div style={styles.textLineOne}>
              Omnia Information Private Limited
            </div>
            <div style={styles.textLine}>
              315/274, Westend marg, Saidulajaib, Saket, New Delhi-110030
            </div>
            <div style={styles.textLine}>
              CIN: U72900DL2015PTC276515 | Email: hr@wiom.in
            </div>
          </div>
          <div
            style={{
              ...styles.rightColumn,
              alignItems: isSmallScreen ? "flex-start" : "flex-end",
              textAlign: isSmallScreen ? "left" : "right",
            }}
          >
            <div style={styles.socialIconsContainer}>
              {[
                {
                  icon: youtubeIcon,
                  link: "https://www.youtube.com/channel/UC8uteMIrx08b648Tduw382A",
                },
                {
                  icon: facebookIcon,
                  link: "https://www.facebook.com/p/Wiom-100093612324053/",
                },
                { icon: twitterIcon, link: "https://x.com/Wiom_Official" },
                {
                  icon: instagramIcon,
                  link: "https://www.instagram.com/wiom_official/?hl=en",
                },
                {
                  icon: linkedinIcon,
                  link: "https://www.linkedin.com/company/wiom/",
                },
              ].map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }} // Optional: Remove underline for icons
                >
                  <div
                    style={styles.socialIcon}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = "#D9008D")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor = "transparent")
                    }
                  >
                    <img
                      src={item.icon}
                      alt={`Social Icon ${index}`}
                      style={styles.iconImage}
                    />
                  </div>
                </a>
              ))}
            </div>

            <p style={styles.footerText}>
              Copyright ® 2023 Omnia Information Pvt. Ltd. All rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

// Main Page Component
const NewHomePage = () => {
  return (
    <div style={{ ...styles.pageContainer }}>
      <video style={styles.backgroundVideo} autoPlay loop muted>
        <source src={bgVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div style={styles.contentOverlay}>
        <div style={styles.navMainWrapper}>
          <Navbar />
          <div style={styles.mainArea}>
            <MainContent />
          </div>
        </div>
        <BottomBar />
      </div>
    </div>
  );
};

// Styles Object
const styles = {
  pageContainer: {
    position: "relative",
    width: "100vw",
    // minHeight: "100vh",
    // height: "100vh",
    overflowY: "auto",
    overflowX: "hidden",
    fontFamily: "Noto Sans", // Add global font
  },

  backgroundVideo: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    objectFit: "cover",
    filter: "brightness(0.6)",
    zIndex: -1,
  },
  contentOverlay: {
    fontFamily: "Noto Sans", // Add global font

    position: "relative",
    // display: "flex",
    // flexDirection: "column",
    // height: "100vh",
    // justifyContent: "space-between",
  },
  // *** The new wrapper for Nav + Main ***
  navMainWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Occupies full viewport height
  },

  mainArea: {
    // height: "100vh",
    flex: 1, // This allows the main content to grow
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // or "flex-start", your call
    alignItems: "center",
    textAlign: "center",
    color: "white",
    fontFamily: "Noto Sans", // Add global font

    // padding: "5vw", // up to you
  },
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 30px",
    color: "white",
    height: "10vh",
    flexShrink: 0,
  },
  navbarLogo: {
    display: "flex",
    alignItems: "center",
    height: "clamp(80px, 5vw, 144px)",
    fontFamily: "Noto Sans, sans-serif",
  },
  logo: {
    paddingTop: "3vh",
    height: "100%", // Ensures the logo scales dynamically with the parent
    width: "auto",
  },
  navbarLogoBottom: {
    display: "flex",
    alignItems: "center",
    maxWidth: "fit-content", // Ensures the width doesn't exceed the button width
    paddingBottom: "28px",
  },
  logoBottom: {
    height: "clamp(30px, 5vw, 44px)", // Dynamic height for better scaling
    maxWidth: "fit-content", // Ensures the width doesn't exceed the button width
  },

  navbarLinks: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  icon: {
    width: "25px",
    height: "25px",
    // cursor: "pointer",
  },
  meetTheTribe: {
    textDecoration: "none",
    fontFamily: "Noto Sans, sans-serif",
    fontSize: "clamp(16px, 2vw, 24px)",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#D9008D",
    cursor: "pointer",
  },
  mainContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "5vw",
    color: "white",
  },
  bottomBar: {
    padding: "20px 240px",
    textAlign: "center",
    backgroundColor: "#161021",
    color: "white",
    fontSize: "0.9rem",
    borderTop: "1px solid #D9008D",
    borderRight: "none",
    borderBottom: "none",
    borderLeft: "none",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    // gap: "20px",
  },
  sectionOne: {
    padding: "42px 0px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // flexWrap: "wrap",
    gap: "10px",
  },

  gridContainer: {
    fontFamily: "Noto Sans", // Add global font
    size: "16px",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "20px 120px", // Updated gap
    textAlign: "left",
    fontWeight: "400px",
  },
  gridItem: {
    fontFamily: "Noto Sans",
    color: "white",
    textAlign: "left",
    padding: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "color 0.3s ease", // Smooth transition for color change
    fontWeight: "400px",
  },
  textWrapper: {
    textDecoration: "none",
    fontFamily: "Noto Sans",
    fontSize: "clamp(14px, 2vw, 16px)",
    fontWeight: 400,
    color: "#faf9fc",
    textAlign: "left", // Left aligned
    // fontWeight:"400px",
  },
  textWrapper2: {
    textDecoration: "none",
    fontFamily: "Noto Sans",
    fontSize: "clamp(14px, 2vw, 16px)",
    fontWeight: 400,
    color: "#faf9fc",
    textAlign: "left", // Left aligned
  },
  textWrapper3: {
    textDecoration: "none",
    fontFamily: "Noto Sans",
    fontSize: "clamp(14px, 2vw, 16px)",
    fontWeight: 400,
    color: "#faf9fc",
    textAlign: "left", // Left aligned
    // fontWeight:"400px",
  },
  rowItem: {
    display: "grid",
    // gap: "28px",
    fontSize: "clamp(14px, 1.5vw, 16px)",
    color: "white",
    // cursor: "pointer",
  },
  contactButton: {
    fontFamily: "Noto Sans", // Add global font
    padding: "16px 34px",
    gap: "8px",
    maxWidth: "380px",

    borderRadius: "12px",
    border: "1px solid rgba(53, 45, 66, 1)",
    backgroundColor: "transparent",
    color: "white",
    opacity: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "16px",
    // width :"fit-content"
  },
  boldText: {
    fontWeight: "bold",
  },
  divider: {
    // padding: "0px 20px",
    border: "1px solid rgba(167, 161, 178, 1)",
    width: "100%",
  },
  partnerPlusFrame: {
    font: "Noto Sans",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "16px",
    padding: "12px 16px",
    backgroundColor: "#161021", // Dark background
    border: "1px solid rgba(255, 255, 255, 0.2)", // Border with transparency
    borderRadius: "12px",
    maxWidth: "350px",
    overflow: "hidden",
    height: "30%", // Dynamic height: half of the parent
    minHeight: "30px", // Minimum height for smaller containers
    maxHeight: "200px", // Maximum height for larger containers
    transition: "background-color 0.3s ease", // Smooth transition for hover effect
    ":hover": {
      backgroundColor: "#D9008D", // Background color changes on hover
    },
  },
  partnerPlusLogo: {
    width: "40px",
    height: "40px",
    objectFit: "contain",
    border: "1px solid rgba(53, 45, 66, 1)",
    borderRadius: "12px",
  },
  partnerPlusContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "4px",
    textAlign: "left", // Align text to the left
  },
  partnerPlusHeader: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: "12px",
    fontWeight: "400",
    color: "#FAF9FC",
    lineHeight: "16px",
  },
  partnerPlusText: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: "20px",
    fontWeight: "700",
    color: "#FAF9FC",
    lineHeight: "28px",
    margin: "0",
  },
  partnerPlusTextWrapper: {
    color: "#FAF9FC",
  },
  partnerPlusTextSpan: {
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  sectionTwo: {
    // padding: "200px 0px",
    // width:"100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
    // padding: "100px 0",
    flexWrap: "wrap",
  },
  // Media query for smaller screens
  "@media (max-width: 768px)": {
    sectionTwo: {
      flexDirection: "column", // Switch to column on smaller screens
      alignItems: "flex-start", // Align items to the left
      textAlign: "left", // Left align all text
      gap: "10px",
    },
  },
  leftColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    textAlign: "left",
  },
  textLineOne: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    color: "#D7D3E0",
    // cursor: "pointer",
  },
  textLine: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    color: "#A7A1B2",
    // cursor: "pointer",
  },
  rightColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "flex-end",
    textAlign: "right",
  },

  socialIconsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end", // Align to the left
    alignItems: "right",
    gap: "24px",
  },
  socialIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    border: "1px solid #352D42",
    borderRadius: "12px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  iconImage: {
    width: "20px",
    height: "20px",
  },
  footerText: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    color: "#A7A1B2",
  },
  "@media (max-width: 768px)": {
    rightColumn: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      alignItems: "flex-start", // Left align icons and text
      textAlign: "left",
    },
    socialIconsContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      textAlign: "left",
      gap: "10px",
    },
  },
};

export default NewHomePage;
